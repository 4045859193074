<template>
  <div>
    <b-modal
      id="location-notes"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Notes')"
      hide-footer
    >
      <b-form-group
        v-if="$route.path.includes('/location')"
      >
        <b-form-input
          v-model="editSupp"
          disabled
        />
      </b-form-group>
      <b-form-group
        v-if="$route.path.includes('/order-item-location')"
      >
        <b-form-input
          v-model="notesClients"
          disabled
        />
      </b-form-group>
    </b-modal></div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['editSupp', 'notesClients'],
  data() {
    return {
      notes: '',
    }
  },
  computed: {
    ...mapGetters([
      'getLocationByPagination',
    ]),
  },
}
</script>

<style scoped>
.form-control {
  padding: 10px;
  background: white;
}
</style>
