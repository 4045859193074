<template>
  <div>
    <b-modal
      id="create-order-location"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      hide-footer
      @close="onCancel"
    >
      <div
        class="existingClient"
        style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
      >
        <b-button
          v-b-toggle.collapse-345
          class="m-1"
          variant="light"
        >
          <b-icon-arrows-expand />
        </b-button>
        <b-form-group
          style="width: 80%; padding-bottom: 10px;"
          :label="$t('SelectExistingClient')"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchClients == null"
                class="search1"
                style=" position: absolute; left: 5px; top: 10px"
              />
              <b-icon-x-circle
                v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                class="search1"
                style="position: absolute; margin-top: -6px; left: 200px; top: 17px; cursor: pointer"
                @click="searchClients = null ,client= false, resetCustomerData()"
              />
              <input
                v-model="searchClients"
                autocomplete="off"
                type="text"
                name="search-box"
                :placeholder="$t('ClientName')"
                :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getFirstNames.length > 0 && noSearch"
              ref="scrollContainer"
              class="dropdrop"
              :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFirstNames"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByOn(result.clientId); noSearch = false"
              >
                <p style="margin: 0">
                  {{ result.nameToDisplay }}
                </p>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-button
          v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
          type="button"
          variant="none"
          class="buttonSubmit"
          style="width: 290px; height: 40px; margin-top: 27px;"
          @click="addNewClient"
        >
          {{ $t(`AddNewLead`) }}
        </b-button>
      </div>
      <section
        v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
        class="custom-modal__wrapper"
        style="border-width: 3px;"
      >
        <b-tabs>
          <b-tab
            :title="$t('PersonalInfo')"
            active
          >
            <b-collapse
              id="collapse-345"
              visible
            >
              <div
                class="hello"
                style="margin-top: 20px;"
              >
                <b-form-group :label="$t('Salutation')">
                  <vue-select
                    v-model="$v.staticForm.salutation.$model"
                    :options="salutation"
                    required
                    :placeholder="$t('SelectSalutation')"
                    :state="validateState('salutation')"
                    aria-describedby="input-1-live-feedback"
                  />
                  <p
                    v-if="staticForm.salutation == null && isSubmitClicked == true"
                    style="color: red"
                  >
                    {{ $t('This_is_a_required_field') }}
                  </p>
                </b-form-group>

                <b-form-group :label="`${$t('FirstName')}`">
                  <b-form-input
                    v-model="$v.staticForm.firstName.$model"
                    :state="validateState('firstName')"
                    placeholder="e.g. Michael"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('LastName')}`">
                  <b-form-input
                    v-model="$v.staticForm.lastname.$model"
                    :state="validateState('lastname')"
                    placeholder="e.g. Goodman"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="hello">
                <b-form-group :label="`${$t('Gender')}`">
                  <vue-select
                    v-model="$v.staticForm.gender.$model"
                    :options="[$t('Male'), $t('Female')]"
                    :placeholder="$t('SelectGender')"
                    required
                    :state="validateState('gender')"
                    aria-describedby="input-5-live-feedback"
                  />
                  <p
                    v-if="staticForm.gender == null && isSubmitClicked == true"
                    style="color: red"
                  >
                    {{ $t('This_is_a_required_field') }}
                  </p>
                </b-form-group>

                <b-form-group :label="`${$t('Email')}`">
                  <b-form-input
                    v-model="$v.staticForm.email.$model"
                    :state="validateState('email')"
                    placeholder="e.g. example@digitbusiness.ch"
                    aria-describedby="input-1-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{
                      $t('Must_be_email_format')
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="`${$t('PhoneNumber')}`">
                  <vue-phone-number-input
                    v-model="staticForm.telephoneNumber"
                    :default-country-code="staticForm.countryCode"
                    :preferred-countries="['CH', 'DE', 'AL']"
                    :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                    @update="getFormattedNumber"
                  />
                </b-form-group>
              </div>
              <div class="hello">
                <b-form-group :label="`${$t('OrderType')}`">
                  <b-form-select
                    v-model="selectedOrderType"
                    :class="{ f: error.oType != '' }"
                    dropup
                    :options="transformOrderTypes"
                    required
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        width: 67%;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                  <p
                    v-if="error.oType != ''"
                    style="margin-top: 0.25rem;
                          font-size: 0.875em;
                          color: #dc3545;"
                  >{{ $t(`${error.oType}`) }}</p>
                </b-form-group>
              </div>
            </b-collapse>
          </b-tab>
        </b-tabs>
      </section>
      <section
        class="custom-modal__wrapper"
        style="border-width: 3px; margin-top:10px"
      >
        <div class="hello">
          <b-form-group :label="`${$t('Location')}`">
            <vue-select
              v-model="location"
              :options="getLocationsByType"
              label="locationName"
              :placeholder="$t('SelectLocation')"
              required
              :reduce="e => e.locationId"
            />
            <p
              v-if="locationReason"
              style="margin-top: 0.25rem; font-size: 0.875em; color: #dc3545;"
            >{{ $t('SelectLocation') }}</p>
          </b-form-group>
          <b-form-group :label="`${$t('OrderNumber')}`">
            <b-form-input
              v-model="orderNumber"
              :placeholder="$t('SelectOrderNr')"
              required
            />
            <p
              v-if="orderReason"
              style="margin-top: 0.25rem; font-size: 0.875em; color: #dc3545;"
            >{{ $t('WriteOrderNumber') }}</p>
          </b-form-group>
        </div>
        <div class="hello">
          <b-form-group
            :label="`${$t('ArticleNumber')}:`"
          >
            <b-form-input
              v-model="$v.form.articleNumber.$model"
              :placeholder="$t('SelectArticleNumber')"
              :state="validateState2('articleNumber')"
              required
            />
            <p
              v-if="$v.form.articleNumber.$anyError"
              style="margin-top: 0.25rem; font-size: 0.875em; color: #dc3545;"
            >
              {{ $t('SelectArticleNumber') }}
            </p>
          </b-form-group>
          <b-form-group
            :label="`${$t('Size')}:`"
          >
            <vue-select
              v-model="$v.form.size.$model"
              :options="filteredSizeTypes"
              :reduce="e => e.name"
              :placeholder="$t('SelectSize')"
              :state="validateState2('size')"
              required
              label="name"
            />
            <p
              v-if="$v.form.size.$anyError"
              style="margin-top: 0.25rem; font-size: 0.875em; color: #dc3545;"
            >
              {{ $t('SelectSize') }}
            </p>
          </b-form-group>
        </div>
        <div style="display:flex; gap: 0%;">
          <div style="display:flex;flex-direction: column;width: 28%;">
            <b-form-group
              :label="`${$t('Price')}:`"
              style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px;width: 90%;"
            >
              <div style="display: flex; justify-content: flex-start; align-items: center;">
                <b-input
                  v-model.number="form.price"
                  type="number"
                  style="width: 90%; margin-right: 5px;"
                />
                <b-form-select
                  v-model="$v.form.currency.$model"
                  :state="validateState2('currency')"
                  dropup
                  required
                  :options="currencies"
                  :aria-placeholder="$t('SelectOrderType')"
                  aria-describedby="input-1-live-feedback"
                  style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                />
              </div>
              <p
                v-if="$v.form.currency.$anyError"
                style="margin-bottom:0px"
              >
                {{ $t('SelectValute') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('paidAmount')"
              style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px;width: 90%;"
            >
              <div style="display: flex; justify-content: flex-start; align-items: center;">
                <b-input
                  v-model.number="form.paidAmount"
                  type="number"
                  style="width: 90%; margin-right: 5px;"
                />
                <b-form-select
                  v-model="$v.form.currency.$model"
                  dropup
                  required
                  :options="currencies"
                  :state="validateState2('currency')"
                  :aria-placeholder="$t('SelectOrderType')"
                  aria-describedby="input-1-live-feedback"
                  style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                />
              </div>
              <p
                v-if="$v.form.currency.$anyError"
                style="margin-bottom:0px"
              >
                {{ $t('SelectValute') }}
              </p>
            </b-form-group>
          </div>
          <div style="display:flex;flex-direction: row;justify-content:flex-start;height: 1%;">
            <b-button
              v-if="!showDiscountForm"
              type="button"
              size="sm"
              class="button"
              style="margin: 50px 20px 0px 0px; width: 280px;"
              @click="showDiscountForm = true"
            >
              <p style="margin: 0">
                + {{ $t('AddDiscount') }}
              </p>
            </b-button>
            <b-form-group
              v-if="showDiscountForm"

              :label="$t('Discount')"
              style="width:30%; display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 35%;"
            >
              <div style="display:flex;">
                <b-input
                  v-model.number="form.discount"
                  type="number"
                  default="0"
                  style="width: 60%;;margin-right: 5px;"
                />
                <b-form-select
                  v-model="discountTypee"
                  style="width: 20%; margin-right: 5px;height:40px; border-radius: 0.25rem; border: 1px solid #D0D5DD"
                >
                  <option value="$">
                    $
                  </option>
                  <option value="€">
                    €
                  </option>
                  <option value="CHF">
                    CHF
                  </option>
                  <option value="%">
                    %
                  </option>
                </b-form-select>
                <div>
                  <b-icon-trash
                    type="button"
                    size="sm"
                    style="background:white;color: black; height: 35px; width: 30px; padding: 5px; border-radius: 10px; margin-bottom: 0px;"
                    @click="showDiscountForm = false,resetDiscountedPrice() "
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group
              v-if="showDiscountForm"
              :label="`${$t('PriceDiscount')}:`"
              style="width:30%; display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 12px; width: 35%;"
            >
              <div style="display:flex">
                <b-input
                  v-model.number="discountedPrice"
                  type="number"
                  style="width: 90%; margin-right: 5px;"
                />
                <b-form-select
                  v-model="$v.form.currency.$model"
                  :state="validateState2('currency')"
                  dropup
                  required
                  :options="currencies"
                  :aria-placeholder="$t('SelectOrderType')"
                  aria-describedby="input-1-live-feedback"
                  style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                />
              </div>
              <p
                v-if="$v.form.currency.$anyError"
                style="margin-bottom:0px"
              >
                {{ $t('SelectValute') }}
              </p>
            </b-form-group>
          </div>
        </div>
        <div class="hello">
          <div style="display: flex; flex-direction: column; padding-top: 10px; width: 25%">
            <b-form-group
              :label="`${$t('PickupDate')}`"
              style="width:100%;display: inline-block; background: white; border-radius: 8px; padding: 8px; color: black;"
            >
              <b-input
                v-model="pickUpDate"
                type="date"
                required
              />
              <p
                v-if="dateReason"

                style="margin-top: 0.25rem; font-size: 0.875em; color: white; margin-bottom: 0px;"
              >{{ $t('ChooseDate') }}</p>
            </b-form-group>
          </div>
        </div>
        <div style="margin: 2%; display: flex">
          <p class="p-0 m-0 ml-50">
            {{ $t('IncludeTax') }}
            <toggle-button
              :value="includeTax"
              :sync="true"
              color="#242F6E"
              @change="includeTax = !includeTax"
            />
          </p>
          <div
            v-if="includeTax == true"
            style=" margin-left: 10px; margin-bottom: 10px"
          >
            <span>18%</span>
          </div>
        </div>
        <b-form-group
          :label="$t('AddAccount')"
          style="width: 100%;padding-bottom: 10px; padding-left: 25px;"
        >
          <vue-select
            v-model="accountId"
            :options="getAccountDetails"
            :reduce="(e) => e.accountId"
            label="name"
            :placeholder="$t('Select')"
            style="width: 30%;"
          />
        </b-form-group>
      </section>
      <section
        class="custom-modal__wrapper"
        style="border-width: 3px; margin-top:10px"
      >
        <div style="display: flex;">
          <AddFile
            ref="addFile1"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadSketch')"
            :file-data-type="'Scetch'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile2"
            :folder="'OrderPhotos'"
            :label-text="$t('UploadContract')"
            :file-data-type="'Contract'"
            :file-name="createFileDataDto"
          />
          <AddFile
            ref="addFile3"
            :folder="'OrderPhotos'"
            :label-text="$t('OtherDocuments')"
            :file-data-type="'OtherDocument'"
            :file-name="createFileDataDto"
          />
        </div>
      </section>
      <b-form-group
        :label="`${$t('Description')}`"
        style="border-width: 3px; margin-top:10px"
      >
        <b-form-textarea
          v-model="description"
        />
        <p
          v-if="descriptionReason == true"
          style="margin-top: 0.25rem; font-size: 0.875em; color: #dc3545;"
        >{{ $t('TypeReason') }}</p>
      </b-form-group>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;  background: white; color: black; border-color: white;font-weight: 600;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';
import AddFile from '@/modals/AddFile.vue'

export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],

  data() {
    return {
      includeTax: false,
      loading: false,
      locationReason: false,
      orderReason: false,
      descriptionReason: false,
      dateReason: false,
      createFileDataDto: [],
      ordererDto: [],
      createOrderItemInStoreFromWarehouseDto: [],
      selectedOrderType: null,
      description: null,
      error: {
        oType: '',
        duplicateError: '',
      },
      filledPickUpDate: false,
      pickUpDate: null,
      currencies: ['€', '$', 'CHF'],
      form: {
        price: 0,
        paidAmount: 0,
        discount: 0,
        size: null,
        articleNumber: null,
        currency: '€',
        taxRate: 0,
      },
      pickUpInStore: true,
      discountTypee: '€',
      showDiscountForm: false,
      orderNumber: null,
      location: null,
      enterPressed: false,
      searchClients: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      isPhoneNumberValid: null,
      client: false,
      isSubmitClicked: false,
      arrowCounter: 0,
      noSearch: false,
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
      staticForm: {
        salutation: null,
        firstName: '',
        lastname: '',
        email: '',
        gender: null,
        telephoneNumber: '',
        countryCode: '',
      },
      accountId: null,
    }
  },
  validations: {
    staticForm: {
      salutation: {
        required,
      },
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastname: {
        required,
        minLength: minLength(3),
      },
      email: {
        email,
      },
      gender: {
        required,
      },
    },
    location: {
      required,
    },
    pickUpDate: {
      required,
    },
    form: {
      articleNumber: {
        required,
      },
      currency: {
        required,
      },
      size: {
        required,
      },
    },
    orderNumber: {
      required,
    },
    selectedOrderType: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getLocationsByType',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames', 'getTotalItemsForClients', 'getClient', 'getLanguage', 'getSizeTypes', 'getAccountDetails']),
    transformOrderTypes() {
      const allOption = { value: null, text: `${this.$t('SelectOrderType')}`, orderType: 'null' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: order.orderType,
          orderType: order.orderType,
        }
      });
      return [allOption, ...orderTypes];
    },
    discountedPrice: {
      get() {
        if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
          return this.form.price - this.form.discount;
        } if (this.discountTypee === '%') {
          if (this.form.price === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.form.discount / 100) * this.form.price;
          return this.form.price - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
          this.form.discount = this.form.price - value;
        } else if (this.discountTypee === '%') {
          if (this.form.price === 0) {
            this.form.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.form.price - value) / (this.form.price * 100);
            this.form.discount = percentageDiscount;
          }
        }
      },
    },
    filteredSizeTypes() {
      return this.getSizeTypes.filter(type => { return type.name !== 'ALL' });
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.price': function (newPrice) {
      if (newPrice < this.form.discount) {
        this.form.discount = 0;
      }
    },
    'form.articleNumber': function (value) {
      this.createOrderItemInStoreFromWarehouseDto[0].articleNumber = value;
    },
    'form.paidAmount': function (value) {
      this.createOrderItemInStoreFromWarehouseDto[0].paidAmount = value;
    },
    // eslint-disable-next-line func-names
    'form.discount': function (newDiscount) {
      if (this.discountTypee === '$' || this.discountTypee === '€' || this.discountTypee === 'CHF') {
        if (newDiscount > this.form.price && newDiscount > this.discountedPrice) {
          this.form.discount = 0;
        }
      } else if (this.discountTypee === '%') {
        if (newDiscount > 100) {
          this.form.discount = 0;
        }
      }
      this.createOrderItemInStoreFromWarehouseDto[0].discount = newDiscount;
    },
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.taxRate = 18;
      } else {
        this.taxRate = 0;
      }
    },
    // eslint-disable-next-line func-names
    'form.currency': function (newCurrency) {
      if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && this.discountTypee != '%') {
        this.discountTypee = newCurrency
      }
      this.createOrderItemInStoreFromWarehouseDto[0].currency = newCurrency;
    },
    discountedPrice(newDiscountedPrice) {
      if (newDiscountedPrice > this.form.price) {
        this.form.price = newDiscountedPrice;
        this.form.discount = 0;
      }
      this.createOrderItemInStoreFromWarehouseDto[0].price = newDiscountedPrice;
    },
    location(value) {
      if (value != null) {
        this.locationReason = false
      }
    },
    orderNumber(value) {
      if (value != null) {
        this.orderReason = false
      }
    },
    description(value) {
      if (value != null || value != '') {
        this.descriptionReason = false
      }
    },
    pickUpDate(value) {
      if (value != null) {
        this.dateReason = false
      }
    },
    discountTypee(value) {
      if (value === '$' || value === '€' || value === 'CHF') {
        this.form.currency = value
      } else if (value === '%') {
        this.form.currency = '€'
      }
      this.form.discount = 0
    },
    form: {
      handler(newValue) {
        if (newValue) {
          if (newValue.discount === undefined || newValue.discount === '' || newValue.discount === null) {
            this.$set(newValue, 'discount', 0);
          }
        }
        if (newValue) {
          if (newValue.currency === undefined || newValue.currency === '' || newValue.currency === null) {
            this.$set(newValue, 'currency', '€');
          }
        }
      },
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    if (this.getOrderTypes.length == 0) {
      this.loadOrderTypes()
    }
  },
  methods: {
    ...mapActions([
      'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById', 'addTransaction',
    ]),
    async onSubmit() {
      if (this.location == null) {
        this.locationReason = true
      }
      if (this.orderNumber == null || this.orderNumber == '') {
        this.orderReason = true
      }
      if (this.description == null || this.description == '') {
        this.descriptionReason = true
      }
      if (this.pickUpDate == null) {
        this.dateReason = true
      }
      if (this.selectedOrderType == null) {
        this.error.oType = 'This_is_a_required_field'
      }
      try {
        this.loading = true;
        // eslint-disable-next-line no-restricted-syntax
        await this.$refs.addFile1.uploadFile();
        await this.$refs.addFile2.uploadFile();
        await this.$refs.addFile3.uploadFile();
        this.$v.staticForm.$touch();
        if (this.$v.staticForm.$anyError) {
          return;
        }
        this.$v.$touch();
        if (this.$v.$anyError) {
          return;
        }
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        let forma
        if (this.createOrderItemInStoreFromWarehouseDto.length <= 0) {
          this.createOrderItemInStoreFromWarehouseDto.push({
            price: this.form.price != '' ? this.form.price : 0,
            paidAmount: this.form.paidAmount != '' ? this.form.paidAmount : 0,
            discount: this.form.discount != '' ? this.form.discount : 0,
            size: this.form.size,
            articleNumber: this.form.articleNumber,
            currency: this.form.currency,
          });
          this.form.paidAmount = this.discountedPrice
          forma = {
            orderNumber: this.orderNumber,
            clientId: this.clientId,
            ordererDto: this.staticForm,
            pickUpInStore: this.pickUpInStore,
            pickUpDate: this.pickUpDate,
            orderType: this.selectedOrderType,
            locationId: this.location,
            description: this.description,
            createOrderItemInStoreFromWarehouseDto: this.createOrderItemInStoreFromWarehouseDto,
            createFileDataDto: this.createFileDataDto,
            accountId: this.accountId,
            taxRate: this.taxRate,
          }
        } else {
          forma = {
            orderNumber: this.orderNumber,
            clientId: this.clientId,
            ordererDto: this.staticForm,
            pickUpInStore: this.pickUpInStore,
            pickUpDate: this.pickUpDate,
            orderType: this.selectedOrderType,
            locationId: this.location,
            description: this.description,
            createOrderItemInStoreFromWarehouseDto: this.createOrderItemInStoreFromWarehouseDto,
            createFileDataDto: this.createFileDataDto,
            accountId: this.accountId,
            taxRate: this.taxRate,
          }
        }
        console.log('forma', forma)
        this.$emit('createOrderLoc', forma)
        this.loading = false;
        this.$refs.modal.hide()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      // this.isSubmitClicked = true;
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false;
        this.fileName = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetCustomerData();
    },
    resetDiscountedPrice() {
      this.discountedPrice = this.form.price;
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    addNewClient() {
      this.client = true;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.telephoneNumber = n.nationalNumber
        this.staticForm.countryCode = n.countryCode
      } else {
        this.staticForm.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    resetPhotos() {
      this.createFileDataDto = []
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.salutation = clientData.salutation
      this.staticForm.firstName = clientData.firstName
      this.staticForm.lastname = clientData.lastName
      this.staticForm.gender = clientData.gender
      this.staticForm.email = clientData.email
      this.staticForm.telephoneNumber = clientData.telephoneNumber
      this.staticForm.countryCode = clientData.countryCode
    },
    resetCustomerData() {
      this.staticForm.salutation = null;
      this.staticForm.firstName = '';
      this.staticForm.lastname = '';
      this.staticForm.email = '';
      this.staticForm.gender = null;
      this.staticForm.telephoneNumber = '';

      this.form.articleNumber = null
      this.form.discount = 0
      this.form.paidAmount = 0
      this.form.size = null
      this.form.price = 0
      this.pickUpDate = null
      this.createFileDataDto = []
      this.createOrderItemInStoreFromWarehouseDto = []
      this.location = null
      this.orderNumber = null
      this.locationReason = false
      this.orderReason = false
      this.descriptionReason = false
      this.description = null
      this.dateReason = false
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.client = false
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.accountId = null
    },
  },
}
</script>
<style lang="scss" scoped>

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%;
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.buttonni:hover{
  background: #FF274F;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;
  flex-direction: column;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}



@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 60% !important;
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
      top: 195px;
      right: 1px;
      max-width: 150px;
      width: 100%;
      height: 25px;
    }
  }
}
</style>
