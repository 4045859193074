<template>
  <div >
    <section class="main no-flex">
      <OrderItemLocationListing />
    </section>
  </div>
</template>

<script>
import OrderItemLocationListing from '../components/location/OrderItemLocationListing.vue';

export default {
  name: 'Home',
  components: {
    OrderItemLocationListing,
  },
}
</script>
<style scoped lang="scss">

@media screen and (max-width: 1600px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
